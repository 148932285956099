import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  heading: {
    margin: `${theme.spacing(4)}px !important`,
  },
  form: {
    width: '80%',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& .MuiCheckbox-root': {
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
  label: {
    width: '100%',
    marginTop: '32px',
    '& .MuiFormLabel-root': {
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
  input: {
    backgroundColor: theme.palette.primary.contrastText,
    borderRadius: '4px',
    width: '100%',
    marginBottom: theme.spacing(4),
    '& .MuiInputAdornment-root button': {
      color: theme.palette.primary.main,
    },
    '& .MuiFilledInput-underline:before': {
      display: 'none',
    },
    '& .MuiFilledInput-underline:after': {
      display: 'none',
    },
    '&:focus-within': {
      boxShadow: `0 0 0 4px ${theme.palette.secondary.main}, 0 0 0 6px ${theme.palette.secondary.contrastText}`,
    },
  },
  select: {
    '& label': {
      marginLeft: theme.spacing(2),
    },
    '&:focus-within': {
      '& label': {
        marginTop: theme.spacing(1),
      },
    },
    '&:focus-within': {
      boxShadow: `0 0 0 4px ${theme.palette.secondary.main}, 0 0 0 6px ${theme.palette.secondary.contrastText}`,
    },
  },
  button: {
    width: '100%',
    margin: `${theme.spacing(8)}px 0`,
    '&:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
      boxShadow: `0 0 0 4px ${theme.palette.primary.contrastText}`,
    },
  },
  radio: {
    color: theme.palette.primary.contrastText,
  },
  row: {
    marginBottom: '32px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

export default useStyles
